import React, { useEffect, useRef, useState } from 'react'
import { styled } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { TimeMark } from '../time-mark'
import { ChatMessage } from '../message'
import { ChatMessageBadge } from '../message-badge'
import { ChatMessageAlert } from '../message-alert'
import {
  IChatNotificationMessage,
  IChatOrderUpdatedMessage,
  IChatTimemarkMessage,
  IUloadChatMessage,
} from 'features/chat-module/interfaces/entity'
import { IP2POrderUser } from 'pages/p2p/myDeals/cards/myTrades.p'
import { useAuthState } from 'useAuthState'
import { useCookiesCustom } from 'shared/hooks/useCookieCustom'

type TChatListMessage =
  | IUloadChatMessage
  | IChatNotificationMessage
  | IChatOrderUpdatedMessage

interface IChatListMessages {
  messages: TChatListMessage[]
  buyerInfo: IP2POrderUser
  sellerInfo: IP2POrderUser
  buyerId: string | undefined
  sellerId: string | undefined
}

type TTimemarkMessages =
  | IUloadChatMessage
  | IChatNotificationMessage
  | IChatOrderUpdatedMessage
  | IChatTimemarkMessage

const ChatListMessages = ({
  messages,
  buyerInfo,
  sellerInfo,
  buyerId,
}: IChatListMessages) => {
  const { t } = useTranslation()
  const { cookie } = useCookiesCustom()
  const uid = useAuthState((state) => state?.userAPI?.uid) || cookie['uid']
  const currentLocale = useTranslation().i18n.language

  const lastMessageRef = useRef<any>(null)

  const [messagesWithTimemark, setMessagesWithTimemark] = useState<
    TTimemarkMessages[]
  >([])

  const integrateTimemarkInMessages = (messages: TChatListMessage[]) => {
    const newArray: any[] = []
    for (let i = messages.length - 1; i >= 0; i--) {
      newArray.push(messages[i])
      if (
        i !== 0 &&
        new Date(messages[i].created_at).getDate() !==
          new Date(messages[i - 1].created_at).getDate()
      ) {
        newArray.push({
          type: 'timemark',
          date: new Date(messages[i].created_at),
        })
      }
    }
    setMessagesWithTimemark(newArray.reverse())
  }

  const getMessageComponent = (messageItem: any) => {
    switch (messageItem.type) {
      case 'user':
        return (
          <ChatMessage
            key={messageItem.id}
            color="default"
            date={new Date(messageItem.created_at)}
            float={uid === messageItem?.sender_id ? 'right' : 'left'}
            attachments={messageItem.attachments}
            message={messageItem.text as string}
            senderNickName={
              buyerId === messageItem?.sender_id
                ? buyerInfo.nickname
                : sellerInfo.nickname
            }
          />
        )
      case 'system':
        return (
          <React.Fragment key={messageItem?.id}>
            <ChatMessageBadge
              date={new Date(messageItem.created_at)}
              content={String(
                t(`chatModule.dealStatus.${messageItem.data.dirty_status}`),
              )}
              status={messageItem.data.dirty_status}
            />
            {messageItem.data.dirty_status === 'respond' && (
              <>
                <ChatMessageBadge
                  content={String(
                    t(
                      `chatModule.dealStatus.${messageItem.data.dirty_status}2`,
                    ),
                  )}
                  status={messageItem.data.dirty_status}
                />
                <ChatMessageAlert header={t(`chatModule.warning.title`)}>
                  {t(`chatModule.warning.text`)}
                </ChatMessageAlert>
              </>
            )}
          </React.Fragment>
        )

      default:
        return (
          <TimemarkWrapper key={messageItem?.id}>
            <TimeMark bigSize date={messageItem.date || new Date()} />
          </TimemarkWrapper>
        )
    }
  }

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [messagesWithTimemark])

  useEffect(() => {
    setMessagesWithTimemark([])
    integrateTimemarkInMessages(messages)
  }, [currentLocale, messages])

  return (
    <MessagesContainer>
      {!!messagesWithTimemark?.length &&
        messagesWithTimemark.map((messageItem) =>
          getMessageComponent(messageItem),
        )}
      <div ref={lastMessageRef} />
    </MessagesContainer>
  )
}

const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgb(255, 255, 255);
    background: #f3f3f8;
    border-radius: 4px;
  }
`

const TimemarkWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0;
`

export { ChatListMessages }
