import React from 'react';
import { useNavigate } from '@pankod/refine-react-router-v6';
import { useCookiesCustom } from '../shared/hooks/useCookieCustom';

type TProps = {
  children: React.ReactNode | JSX.Element | undefined | null;
  fallback?: React.ReactNode | JSX.Element;
  loading?: React.ReactNode | JSX.Element;
  isLoading?: boolean;
};

const ProtectedRoute = ({ children, fallback, isLoading, loading }: TProps) => {
  const navigate = useNavigate();
  const {cookie} = useCookiesCustom();

  if (isLoading) return <>{loading}</>;
  if (!!cookie['token']) return <>{children}</>;
  else {
    navigate('/login');
    return <>{fallback}</>;
  }
};

export default ProtectedRoute;
