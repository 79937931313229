import {ConfigProvider, Form, Input, Slider, Typography} from "@pankod/refine-antd"
import {useNavigate} from "@pankod/refine-react-router-v6"
import {ButtonsTypes} from "shared/components/ui/button/filledButton.p"
import CustomSelect from "shared/components/ui/select"
import {toFixed} from "shared/helpers/toFixed"
import {useCreate, useTranslate} from "@pankod/refine-core"
import {ChangeEvent, FC, useEffect, useState} from "react"
import {IP2PCollapseInputData} from "./interface"
import Button from "shared/components/ui/button"
import styled from "styled-components"
import {FormatNumber} from "shared/helpers/formatNumber"
import {SwitchTwoElem} from "shared/components/ui/switchTwoElem"
import {useGetConfigCommision} from "../../../../shared/hooks/currency-info/use-get-config-commision";
import {TWalletState, useWalletState} from "../../../../useWalletState";
import {useAuthState} from "../../../../useAuthState";
import {useCookiesCustom} from '../../../../shared/hooks/useCookieCustom';

export const CollapseOpenInputData: FC<IP2PCollapseInputData> = ({
                                                                     dataAd,
                                                                     typeText,
                                                                     setIsOpen,
                                                                     methodArray,
                                                                     item,
                                                                     setFromCard,
                                                                     setOpenModal,
                                                                     user
                                                                 }) => {
    const {cookie} = useCookiesCustom();
    const uid = useAuthState((state) => state?.userAPI?.uid) || cookie['uid'];
    const isAuth = !!cookie['token']?.length;
    const login = useAuthState((state) => state.login);
    const register = useAuthState((state) => state.register);
    const balances = useWalletState((state) => state.balances);

    const getAmountByCurrency = (balances: TWalletState['balances'], currency: string) => {
        const foundBalance = balances.find((balance) => balance.name === currency);
        return foundBalance ? Number(foundBalance.balance.amount) : 0;
    }

    const balance = getAmountByCurrency(balances, dataAd.ccy);

    const navigate = useNavigate();
    const t = useTranslate();
    const {configData} = useGetConfigCommision({configKey: 'p2p_commission'})

    const [error, setError] = useState<{
        buy?: string;
        payment?: string;
    }>({});

    const [form] = Form.useForm();

    const [selectData, setSelectData] = useState('');

    const {mutate} = useCreate<{ data: { id: string } }>({
        mutationOptions: {retry: false},
    });

    const getCurrentFiatSumm = (valueOnUndefiend = 0) => {
        return form.getFieldValue('buy') ? form.getFieldValue('buy') : valueOnUndefiend
    }

    const getCurrentCCYSumm = (valueOnUndefiend = 0) => {
        return form.getFieldValue('sell') ? form.getFieldValue('sell') : valueOnUndefiend
    }

    const [isFiat, setIsFiat] = useState(dataAd.type === 'sell')

    const handleCreate = async () => {
        if (!isAuth) {
            login();
            return;
        } else {
            if (!!user) {
                if (!user.nickname?.length) {
                    setOpenModal(true);
                    setFromCard(true);
                } else {
                    const error: {
                        buy?: string;
                        payment?: string;
                    } = {};


                    if (
                        getCurrentCCYSumm() === undefined ||
                        (!isNaN(+getCurrentFiatSumm()) &&
                            +toFixed(+(+getCurrentFiatSumm() / +dataAd.price), 8) <
                            +toFixed(+(+dataAd.min_sum_limit / +dataAd.price), 8))
                    ) {
                        error.buy = 'min';
                    }

                    if (
                        !isNaN(+getCurrentFiatSumm()) &&
                        +toFixed(+(+getCurrentFiatSumm() / +dataAd.price), 8) >
                        +toFixed(+(+dataAd.max_sum_limit / +dataAd.price), 8)
                    ) {
                        error.buy = 'max';
                    }

                    if (!Boolean(selectData)) {
                        error.payment = 'err';
                    }

                    if (Object.keys(error)) setError(error);

                    if (
                        !error.buy &&
                        !error.payment &&
                        +getCurrentCCYSumm() >=
                        +toFixed(Number(+dataAd?.min_sum_limit / +dataAd?.price), 8) &&
                        +getCurrentCCYSumm() <=
                        +toFixed(Number(+dataAd?.max_sum_limit / +dataAd?.price), 8)
                    )
                        mutate(
                            {
                                dataProviderName: 'p2p',
                                resource: 'order',
                                values: {
                                    ad_id: dataAd.id,
                                    sum: getCurrentCCYSumm() === '' ? undefined : +getCurrentCCYSumm(),
                                    payment_id: selectData,
                                },
                            },
                            {
                                onSuccess: (res) => {
                                    navigate(`/p2p/order/${res.data.data.id}`);
                                },
                                onError: (res) => {
                                    if (
                                        res.response.data.message ===
                                        'Sum more then available trade amount'
                                    ) {
                                        setError({...error, buy: 'max'});
                                    } else
                                        setError(() => ({
                                            buy: !!res?.response?.data?.errors?.sum?.join(' ')
                                                ? res?.response?.data?.errors?.sum?.join('. ')
                                                : undefined,
                                            payment:
                                                res.message === 'The payment id field is required.'
                                                    ? 'The payment id field is required.'
                                                    : undefined,
                                        }));
                                },
                            }
                        );
                }
            }
        }
    };

    const handleCancel = () => {
        if (!isAuth) {
            register();
        } else {
            form.setFieldValue('buy', undefined);
            form.setFieldValue('sell', undefined);
            form.setFieldValue('paymentMethod', undefined);
            setIsOpen(false);
        }
    };

    const myPaymentMethods = methodArray(true, true)
    const addMethodPayments = methodArray()

    const avaivaiblePaymentMethods = myPaymentMethods.filter((item) => {
        return !!addMethodPayments.find((it) => {
            return it.title === item.title && it.type === item.type
        })
    })

    const [isNoAvaivailblePaymentMethod, setIsNoAvailablePaymentMethod] = useState(avaivaiblePaymentMethods.length === 0)
    const [isDontHaveAmountBalance, setIsDontHaveAmountBalance] = useState(balance < (+dataAd.min_sum_limit / +dataAd.price))
    const [isMyAdd, setIsMyAdd] = useState(dataAd.owner_id === uid)
    const [isDisableAdd, setIsDisableAdd] = useState(isMyAdd || isNoAvaivailblePaymentMethod || (isDontHaveAmountBalance && dataAd.type === 'buy'))
    const [maxAddInCCY, setMaxAddInCCY] = useState(parseFloat(toFixed(+dataAd.max_sum_limit / +dataAd.price, 8)))
    const [maxAddInFiat, setMaxAddInFiat] = useState(+dataAd.max_sum_limit)
    const [balanceInFiat, setBalanceInFiat] = useState(+(+FormatNumber(balance * +dataAd.price)))
    const [balanceInCCY, setBalanceInCCY] = useState(balance)

    useEffect(() => {
        setIsNoAvailablePaymentMethod(avaivaiblePaymentMethods.length === 0)
    }, [avaivaiblePaymentMethods.length])

    useEffect(() => {
        setIsDontHaveAmountBalance(balance < (+dataAd.min_sum_limit / +dataAd.price))
        setBalanceInCCY(balance)
    }, [balance, dataAd.min_sum_limit, dataAd.price])

    useEffect(() => {
        setIsMyAdd(dataAd.owner_id === uid)
    }, [dataAd.owner_id, uid])

    useEffect(() => {
        setIsDisableAdd(isMyAdd || isNoAvaivailblePaymentMethod || (isDontHaveAmountBalance && dataAd.type === 'buy'))
    }, [isMyAdd, isNoAvaivailblePaymentMethod, isDontHaveAmountBalance, dataAd.type])

    useEffect(() => {
        setMaxAddInCCY(parseFloat(toFixed(+dataAd.max_sum_limit / +dataAd.price, 8)))
        setMaxAddInFiat(+dataAd.max_sum_limit)
    }, [dataAd.max_sum_limit, dataAd.price])

    useEffect(() => {
        setBalanceInFiat(+(+FormatNumber(balance * +dataAd.price)))
    }, [balance, dataAd.price])

    console.log('isDontHaveAmountBalance', isDontHaveAmountBalance, balance, +dataAd.min_sum_limit / +dataAd.price, 'isDisableAdd', isDisableAdd)

    const getCurrentMax = (isFiat: boolean) => {
        if (isDontHaveAmountBalance) {
            if (isFiat) {
                return maxAddInFiat;
            } else {
                return maxAddInCCY;
            }
        } else {
            if (dataAd.type === 'sell') {
                if (isFiat) {
                    return maxAddInFiat;
                } else {
                    return maxAddInCCY;
                }
            } else {
                if (isFiat) {
                    if (maxAddInFiat > balanceInFiat) {
                        return Number(balanceInFiat) !== 0 ? balanceInFiat : maxAddInFiat;
                    } else {
                        return maxAddInFiat;
                    }
                } else {
                    if (maxAddInCCY > balanceInCCY) {
                        return balanceInCCY;
                    } else {
                        return maxAddInCCY;
                    }
                }
            }
        }
    }

    const currentMax = getCurrentMax(!isFiat)

    const PaymentMethodHelperText = () => {
        return (!!error.payment || isNoAvaivailblePaymentMethod) &&
            ((!!error.payment && (
                    <RedText>
                        {t('dashboard.collapseBody.paymentRequired')}
                    </RedText>
                )) || (avaivaiblePaymentMethods.length === 0 && (
                    <RedText>
                        {t('dashboard.collapseBody.paymentNoAddMethods')}
                    </RedText>
                ))
            )
    }

    const FormWithCustomLabelHelpText = () => {
        return (
            <span className={'helpInput'}>
        {t(`dashboard.collapseBody.${error.buy}`)}{' '}
                {error.buy === 'min'
                    ? toFixed(
                        +dataAd?.min_sum_limit / +dataAd?.price,
                        item?.ccy === 'USDT' ? 2 : 8
                    )
                    : toFixed(
                        currentMax,
                        item?.ccy === 'USDT' ? 2 : 8
                    )}{' '}
                {dataAd.ccy}
      </span>
        )
    }

    const minLimitFiat = () => {
        return parseFloat((+dataAd.min_sum_limit).toFixed(2))
    }

    const minLimitCCY = () => {
        return parseFloat((+dataAd.min_sum_limit / +dataAd.price).toFixed(8))
    }

    const maxLimitFiat = () => {
        return parseFloat((+dataAd.max_sum_limit).toFixed(2))
    }

    const maxLimitCCY = () => {
        return parseFloat((+dataAd.max_sum_limit / +dataAd.price).toFixed(8))
    }


    const handleChangeSell = (input: string) => {
        let value = input.replace(/[^\d.-]/g, '')


        if (!isNaN(parseFloat(value)) && isFinite(Number(value))) {
            form.setFieldValue(
                'buy',
                !!value && !!dataAd.price ? parseFloat(String(Math.round(+(+value * +dataAd.price)))) : 0
            );
        }
        form.setFieldValue('sell', value)

        const {max, min} = {
            min: +parseFloat(Number(+dataAd?.min_sum_limit / +dataAd?.price).toFixed(8)),
            max: +parseFloat(Number(+dataAd?.max_sum_limit / +dataAd?.price).toFixed(8)),
        };

        if (+value < min && value !== '')
            setError(() => ({...error, buy: 'min'}));
        else if (+value > max && value !== '')
            setError(() => ({...error, buy: 'max'}));
        else setError(() => ({...error, buy: undefined}));
    };

    const handleChangeBuy = (input: string) => {
        const value = input.replace(/[^\d.-]/g, '');

        if (!isNaN(parseFloat(value)) && isFinite(Number(value))) {
            form.setFieldValue('buy', input)
            form.setFieldValue(
                'sell',
                !!value && !!dataAd.price ? toFixed(+(+value / +dataAd.price), 8) : 0
            );
        }

        form.setFieldValue('buy', input)

        if (
            +toFixed(+(+value / +dataAd.price), 8) <
            +toFixed(+(+dataAd.min_sum_limit / +dataAd.price), 8) &&
            value !== ''
        )
            setError(() => ({...error, buy: 'min'}));
        else if (
            +toFixed(+(+value / +dataAd.price), 8) >
            getCurrentMax(isFiat) &&
            value !== ''
        )
            setError(() => ({...error, buy: 'max'}));
        else setError(() => ({...error, buy: undefined}));
    };

    const handleChangeCustomInput = (event: ChangeEvent<HTMLInputElement>) => {
        if (isFiat) {
            handleChangeSell(event.target.value + '')
        } else {
            handleChangeBuy(event.target.value + '')
        }
    }

    const getSpanColor = () => {
        return isDisableAdd
            ? '#767687'
            : !!getCurrentCCYSumm() || !!getCurrentFiatSumm() ? '#FBFBFF' : '#767687'
    }

    const getCustomInputPlaceholder = () => {
        return isFiat
            ? typeText.placeholder
            : `${toFixed(
                Number(dataAd.min_sum_limit),
                2
            )} - ${toFixed(
                Number(dataAd.max_sum_limit),
                2
            )}`
    }

    const handleChangeCustomSlider = (value: number) => {
        if (isFiat) {
            handleChangeBuy(value + '')
        } else {
            handleChangeSell(value + '')
        }
    }

    const getComission = () => {
        if (!!configData?.value) {
            return `${FormatNumber((+getCurrentCCYSumm() * +configData?.value))} ${dataAd.ccy}`
        } else return `${isFiat ? +dataAd.min_sum_limit : +dataAd.min_sum_limit / +dataAd.price} ${dataAd.ccy}`
    }

    const calcCurrency = () => {
        return isFiat ? dataAd.fiat : dataAd.ccy
    }

    const getSummary = () => {
        return `${dataAd.type === 'sell'
            ? (+getCurrentFiatSumm() + (+getCurrentFiatSumm() * parseFloat(configData?.value + ''))).toFixed(2)
            : FormatNumber(getCurrentCCYSumm())} ${calcCurrency()}`;
    }

    const calcMinSlider = () => {
        return isFiat ? minLimitFiat() : minLimitCCY()
    }

    const calcMaxSlider = () => {
        return isFiat ? maxLimitFiat() : maxLimitCCY()
    }

    const calcSliderStep = () => {
        return isFiat
            ? (calcMaxSlider() - +dataAd.min_sum_limit) / 100
            : (calcMaxSlider() - +dataAd.min_sum_limit / +dataAd.price) / 100
    }

    const calcSliderValue = () => {
        return isFiat
            ? getCurrentFiatSumm((+dataAd.min_sum_limit / +dataAd.price))
            : getCurrentCCYSumm((+dataAd.min_sum_limit / +dataAd.price))
    }

    const showCurrencyText = () => {
        return isFiat ? dataAd.ccy : dataAd.fiat
    }

    const getMinLimitSliderText = () => {
        const showMinLimit = () => isFiat ? minLimitCCY() : minLimitFiat()
        return `
        ${t('dashboard.collapseBody.minLimit')}
        ${showMinLimit()}
         ${showCurrencyText()}`
    }

    const getMaxLimitSliderText = () => {
        const showMaxLimit = () => isFiat ? maxLimitCCY() : maxLimitFiat()
        return `
        ${t('dashboard.collapseBody.maxLimit')}
        ${showMaxLimit()}
        ${showCurrencyText()}
        `
    }

    useEffect(() => {
        if (isFiat) {
            form.setFieldValue('sell', minLimitCCY())
        } else {
            form.setFieldValue('buy', minLimitFiat())
        }
    }, [])

    return (
        <ConfigProvider
            theme={{
                components: {
                    Input: {
                        colorPrimaryHover: '#F3F3F8',
                    },
                },
            }}
        >
            <MainWrapper>
                <FormStyled
                    form={form}
                    name="basic"
                    autoComplete="off"
                    layout={'vertical'}
                    disabled={isDisableAdd !== isNoAvaivailblePaymentMethod}
                >
                    <FormWrapper>
                        <CoinPriceWrapper>
                            <BaseTypographyText $fontWeight={700}>
                                {t('dashboard.collapseBody.coinPrice')}
                            </BaseTypographyText>
                            <GreenText $fontWeight={700}>
                                {`${(+dataAd.price).toFixed(2)} ${dataAd.fiat}`}
                            </GreenText>
                        </CoinPriceWrapper>
                        <RightSideWrapperStyled>
                            <FormWithCustomLabel
                                name={'buy'}
                                label={
                                    <CustomLabelWrapper>
                                        <BaseTypographyText
                                            isDisabled={isDisableAdd !== isNoAvaivailblePaymentMethod}>
                                            {
                                                isFiat ? typeText.input[1] : t(`dashboard.collapseBody.${dataAd.type === 'sell' ? 'buy' : 'sell'}.useFiat`)
                                            }
                                        </BaseTypographyText>
                                        <BaseTypographyText
                                            isDisabled={isDisableAdd !== isNoAvaivailblePaymentMethod}>
                                            {
                                                isFiat ?
                                                    `= ${form.getFieldValue('buy') && !isNaN(+form.getFieldValue('buy')) ? form.getFieldValue('buy') : 0} ${dataAd.fiat}`
                                                    :
                                                    `= ${FormatNumber(getCurrentCCYSumm())} ${dataAd.ccy}`
                                            }
                                        </BaseTypographyText>
                                    </CustomLabelWrapper>
                                }
                                help={!!error.buy && (FormWithCustomLabelHelpText())}
                            >
                                <FormWithCustomLabelWrapper>
                                    <CustomInput
                                        type={'number'}
                                        status={!!error.buy ? 'error' : ''}
                                        aria-label={'sell'}
                                        onChange={(event) => handleChangeCustomInput(event)}
                                        suffix={
                                            <SpanStyled $color={getSpanColor()}>
                                                {isFiat ? dataAd.ccy : dataAd.fiat}
                                            </SpanStyled>
                                        }
                                        placeholder={getCustomInputPlaceholder()}
                                        value={isFiat ? form.getFieldValue('sell') : form.getFieldValue('buy')}
                                    />
                                    <SwitchTwoElem
                                        onClick={() => setIsFiat(!isFiat)}
                                        isFirstActive={isFiat}
                                        firstItem={dataAd.ccy}
                                        secondItem={dataAd.fiat}
                                        isDisabled={isDisableAdd !== isNoAvaivailblePaymentMethod}
                                    />
                                </FormWithCustomLabelWrapper>
                                <div>
                                    <CustomSlider
                                        value={calcSliderValue()}
                                        tooltip={{open: false}}
                                        onChange={(value) => handleChangeCustomSlider(value)}
                                        min={calcMinSlider()}
                                        max={calcMaxSlider()}
                                        step={calcSliderStep()}
                                        disabled={isDisableAdd !== isNoAvaivailblePaymentMethod}
                                    />
                                    <SliderTextWrapper>
                                        <SliderText isDisabled={isDisableAdd !== isNoAvaivailblePaymentMethod}>
                                            {getMinLimitSliderText()}
                                        </SliderText>
                                        <SliderText isDisabled={isDisableAdd !== isNoAvaivailblePaymentMethod}>
                                            {getMaxLimitSliderText()}
                                        </SliderText>
                                    </SliderTextWrapper>
                                </div>
                            </FormWithCustomLabel>
                            <FormItemStyled
                                name={'paymentMethod'}
                                help={PaymentMethodHelperText()}
                                label={
                                    <BaseTypographyText isDisabled={isDisableAdd !== isNoAvaivailblePaymentMethod}>
                                        {t('dashboard.collapseBody.chooseMethod')}
                                    </BaseTypographyText>
                                }
                            >
                                <CustomizeSelector
                                    status={!!error.payment || isNoAvaivailblePaymentMethod ? 'error' : ''}
                                    placeholder={t('dashboard.collapseBody.paymentMethod')}
                                    data={avaivaiblePaymentMethods}
                                    disabled={isDisableAdd}
                                    hasError={!!error.payment || isNoAvaivailblePaymentMethod}
                                    eventName={'selectPaymentMethod'}
                                    setValue={(data) => {
                                        setSelectData(data);
                                        form.setFieldValue('paymentMethod', data);
                                    }}
                                />
                            </FormItemStyled>
                            {
                                !isNoAvaivailblePaymentMethod && (
                                    <WrapperComisson>
                                        {
                                            isDisableAdd ?
                                                <RedText>
                                                    {
                                                        isMyAdd ? t("dashboard.collapseBody.errors.myAdd") :
                                                            isDontHaveAmountBalance ? t("dashboard.collapseBody.errors.noMoneyForAdd") : ''
                                                    }
                                                </RedText>
                                                :
                                                (
                                                    <SummaryComissionWrapper>
                                                        <BlockInfo>
                                                            <Typography.Text>
                                                                {t("dashboard.collapseBody.summary")}
                                                            </Typography.Text>
                                                            <Typography.Text>
                                                                {getSummary()}
                                                            </Typography.Text>
                                                        </BlockInfo>
                                                        {
                                                            dataAd.type === 'sell' && (
                                                                <BlockInfo>
                                                                    <SmallText>
                                                                        {t("dashboard.collapseBody.commission")}
                                                                    </SmallText>
                                                                    <SmallText>
                                                                        {getComission()}
                                                                    </SmallText>
                                                                </BlockInfo>
                                                            )
                                                        }
                                                    </SummaryComissionWrapper>
                                                )
                                        }
                                    </WrapperComisson>
                                )
                            }
                        </RightSideWrapperStyled>
                    </FormWrapper>
                </FormStyled>
                <ButtonsWrapper>
                    <Button
                        width={'180px'}
                        height={'40px'}
                        disabled={isDisableAdd}
                        onClick={handleCreate}
                    >
                        {isAuth ? typeText.text : t('p2p.login')}
                    </Button>

                    <Button
                        width={'180px'}
                        height={'40px'}
                        onClick={handleCancel}
                        type={ButtonsTypes.outlined}
                    >
                        {isAuth ? t('dashboard.collapseBody.cancel') : t('p2p.register')}
                    </Button>
                </ButtonsWrapper>
            </MainWrapper>
        </ConfigProvider>
    )
}

const RightSideWrapperStyled = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const FormStyled = styled(Form)`
    padding: 24px;
    width: 100%;
`;

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const CoinPriceWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
`;

const CustomInput = styled(Input)`
    margin-top: 0;
    background-color: unset;
`

const SpanStyled = styled.span<{ $color?: string }>`
    color: ${(props) => props.$color};
`

const CustomSlider = styled(Slider)`
    margin-top: 12px;
    margin-bottom: 8px;
    max-width: 100%;

    & .ant-slider-handle::after {
        background-color: #703DF2 !important;
        border-radius: 4px;
        inset-block-start: -3px !important;;
    }

    & .ant-slider-track {
        background-color: #703DF2 !important;
    }
`

const BaseTypographyText = styled(Typography.Text) <{
    isDisabled?: boolean,
    color?: string,
    $fontWeight?: number
}>`
    color: ${({isDisabled, color}) => isDisabled ? "#474755" : color};
    font-weight: ${(props) => props.$fontWeight};
`

const GreenText = styled(BaseTypographyText)`
    color: #58BF92;
`;

const CustomLabelWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

const SliderText = styled(BaseTypographyText)`
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`

const FormWithCustomLabel = styled(Form.Item)`
    flex: 1;

    & label {
        width: 100%
    }
`

const FormWithCustomLabelWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
`;

const CustomizeSelector = styled(CustomSelect) <{ hasError: boolean }>`

    margin-bottom: ${({hasError}) => hasError ? '4px' : 'unset'};

    & .ant-select-selector {
        background-color: unset !important;
        border-color: ${({hasError}) => hasError ? '#F16063 !important' : 'unset'};
    }
`

const SliderTextWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
`;

const FormItemStyled = styled(Form.Item)`
    height: 100%;
    flex: 1.5;
`;

const RedText = styled(Typography.Text)`
    color: #F16063;
    font-size: 14px;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    gap: 16px;
    justify-content: end;
    padding-right: 24px;
    padding-bottom: 24px;
`;

const WrapperComisson = styled.div`
    border-top: 1px solid rgba(65, 61, 80, 0.50);
    display: flex;
    flex-direction: column;
    padding-top: 16px;
`;

const SummaryComissionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const BlockInfo = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
`;

const SmallText = styled(BaseTypographyText)`
    font-size: 12px;
`
