import React, { useState, useEffect } from 'react';


interface ITimer {
  handleFinish: () => void;
  initSeconds: number | undefined;
}

const Timer = ({initSeconds, handleFinish}: ITimer) => {
  const [timeLeft, setTimeLeft] = useState(initSeconds);
  const [isRunning, setIsRunning] = useState(true);

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isRunning && timeLeft && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => (prevTime as number) - 1);
      }, 1000);
    } else {
      setIsRunning(false)
      handleFinish();
    }

    return () => clearInterval(timer);
  }, [isRunning, timeLeft]);

  return (
    <span>
      {!!timeLeft ? formatTime(timeLeft) : '00:00'}
    </span>
  );
};

export default Timer;
