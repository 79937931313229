import { useCookies } from 'react-cookie';
import { CookieSetOptions } from 'universal-cookie';
import { All_DOMAINS } from '../../packages/keycloak-client/constants';

export const defaultkeysCookies = [
  'refreshTokenTimestamp',
  'currentServerTime',
  'remainingTime',
  'oidc.user',
  'inviteToken',
  'email',
  'uid',
  'token',
  'refresh_token',
  'isExitUser',
  'fromPortal',
  'fromFinms',
  'fromEdenex',
  'currentPointId',
  'partnerPointId',
  'favorites',
  'toLogin'
];

export const configDefaulte: CookieSetOptions = {
  path: '/',
  domain: '',
  secure: false,
  httpOnly: false,
  sameSite: 'lax'
};

export const useCookiesCustom = (keysCookies?: string[]) => {
  const [cookie, setCookie, removeCookie] = useCookies(keysCookies ?? defaultkeysCookies);

  const setCookieCustom = (key: string, value: any, config?: CookieSetOptions) => {
    All_DOMAINS.forEach((domain) => {
      setCookie(key, value, config ?? { ...configDefaulte, domain });
    });
  };

  const removeCookieCustom = (key: string, config?: CookieSetOptions) => {
    All_DOMAINS.forEach((domain) => {
      removeCookie(key, config ?? { ...configDefaulte, domain });
    });
  };

  return {
    cookie,
    setCookie: setCookieCustom,
    removeCookie: removeCookieCustom
  };
};
