import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { notification } from 'antd'
import { Popconfirm, Tooltip, useSimpleList } from '@pankod/refine-antd'
import {
  BaseRecord,
  HttpError,
  useCustomMutation,
  useList,
  useUpdate,
} from '@pankod/refine-core'
import { useNavigate } from '@pankod/refine-react-router-v6'
import { styled } from 'styled-components'

import { IConfigCurrencyPair } from 'features/p2p/createOrder/types'
import { axios } from '../../../../shared/exios'
import { AdHeading } from './header'
import { AdContent } from './content'
import { AdPaymentTypes } from './paymentTypes'
import { DealTerms } from './dealTerms'
import { IPaymentsType } from 'pages/p2p/dashboard/interface'
import { PaymentTypesBlock } from './paymentTypesBlock'

const getRegionById = async (id: string, dispatch: (arg: string) => void) => {
  if (id) {
    await axios.get(`/p2p/api/regions/${id}`).then((value) => {
      dispatch(value.data.data.name)
    })
  }
}

const Params = ({ data }: { data: BaseRecord }) => {
  const { t } = useTranslation()
  const { mutate } = useUpdate()

  useList<IConfigCurrencyPair, HttpError>({
    resource: 'currency-pair',
    dataProviderName: 'config',
    config: {
      pagination: {
        current: 1,
        pageSize: 30,
      },
      filters: [
        {
          field: 'is_available_p2p',
          operator: 'eq',
          value: true,
        },
      ],
    },
    queryOptions: {
      refetchInterval: 60000,
      onSuccess: (data) => {
        const pairName = `${record.ccy}${record.fiat}`
        const latestPrice = data.data.find(
          (item) => item.pair == pairName,
        )?.latest_price
        latestPrice &&
          setLatestPriceRange({
            min: +latestPrice * (80 / 100),
            max: +latestPrice * (130 / 100),
          })
      },
    },
  })

  const iconsData = useList<IPaymentsType, HttpError>({
    dataProviderName: `p2p`,
    resource: `payment-types`,
  })

  const { listProps: comissionData } = useSimpleList({
    dataProviderName: 'config',
    resource: 'currency-constraint',
    initialFilter: [
      {
        field: 'currency',
        operator: 'eq',
        value: `${data?.dataSource[0]?.ccy}`,
      },
    ],
  })

  const { listProps: reactionTime } = useSimpleList({
    dataProviderName: 'config',
    resource: 'config',
    initialFilter: [
      { field: 'key', operator: 'eq', value: 'respond_reaction_time' },
    ],
  })
  const navigate = useNavigate()
  const [record, setRecord] = useState(data)
  const [latestPriceRange, setLatestPriceRange] = useState<{
    min: string | number
    max: string | number
  }>({
    min: '0',
    max: '50',
  })
  const [regionName, setRegionName] = useState('')

  useEffect(() => {
    if (data.dataSource) {
      setRecord(data?.dataSource[0])
      getRegionById(data?.dataSource[0]?.region_id, setRegionName)
    }
  }, [data])

  const renderPaymentTypesBlock = (record: BaseRecord, type: string) => {
    return (
      <PaymentTypesBlock
        t={t}
        iconsData={iconsData}
        record={record}
        type={type}
      />
    )
  }

  const changeStatus = (deleteAnn: boolean) => {
    let newStatus = ''

    if (deleteAnn) {
      newStatus = 'deleted'
    } else if (record.status === 'template') {
      newStatus = 'created'
    } else {
      newStatus = 'template'
    }

    mutate(
      {
        resource: 'offer',
        dataProviderName: 'p2p',
        id: `${record.id}`,
        values: {
          status: `${newStatus}`,
        },
      },
      {
        onError: (error) => {
          if (error.message === 'Unable to publish Ad, you already have one') {
            notification.error({
              message: t('messages.error.adCreateError'),
              description: t('messages.error.maxAdLimit'),
            })
          }
        },
      },
    )

    if (deleteAnn) navigate('/p2p/my-announcements')
  }

  const isExecuted = data.dataSource[0].status === 'executed'
  const hasActiveOrders = data.dataSource[0].orders_active_count !== 0
  const isEnabled = !isExecuted && !hasActiveOrders
  const unableEditText = !isEnabled
    ? [
        hasActiveOrders && t('announcements.status.haveOpenDeals'),
        isExecuted && t('announcements.status.adExecuted'),
        t('announcements.status.cannotEditAd'),
      ]
        .filter(Boolean)
        .join(' ')
    : undefined

  const onConfirm = () =>
    navigate(
      `/p2p/edit-offer/${record.type}/${record.id}/${record.ccy}${record.fiat}`,
    )

  const getConfirmTitle = () =>
    record.price < latestPriceRange.min || record.price > latestPriceRange.max
      ? t('announcements.buttons.editAdWithUnaccaptablePrice')
      : t('announcements.buttons.editAd') + '?'

  const popConfirmProps = {
    okButtonProps: {
      style: { paddingInline: 32 },
      size: 'large' as const,
    },
    cancelButtonProps: {
      style: {
        paddingInline: 32,
        background: 'none',
        color: 'white',
        borderColor: '#7B59FD',
      },
      size: 'large' as const,
    },
  }
  return (
    <>
      <CardContainer>
        <AdCard>
          <AdHeading record={record} />
          <AdContent
            reactionTime={
              reactionTime.dataSource &&
              reactionTime.dataSource[0].respond_reaction_time
            }
            regionName={regionName}
            record={record}
            data={data}
            comissionData={comissionData}
          />
          <AdPaymentTypes
            record={record}
            PaymentTypesBlock={renderPaymentTypesBlock}
          />
          <DealTerms record={record} />
        </AdCard>
      </CardContainer>
      <FRow style={{ gap: '16px', marginTop: '10px' }}>
        <Popconfirm
          disabled={!isEnabled}
          onConfirm={() => changeStatus(false)}
          cancelText={t('payments.popConfirm.No')}
          okText={t('payments.popConfirm.Yes')}
          title={
            record.status === 'template'
              ? t('announcements.public.draft')
              : t('announcements.public.back')
          }
          {...popConfirmProps}
          overlayInnerStyle={{
            maxWidth:
              record.price < latestPriceRange.min ||
              record.price > latestPriceRange.max
                ? '376px'
                : '260px',
          }}
        >
          {!isEnabled ? (
            <Tooltip
              trigger="hover"
              title={unableEditText}
              placement="top"
              overlayInnerStyle={{ width: 'auto' }}
            >
              <ActionButtonStyled $disabled={!isEnabled}>
                {record.status !== 'template'
                  ? t('announcements.buttons.params.moveToDrafts')
                  : t('announcements.buttons.params.postAd')}
              </ActionButtonStyled>
            </Tooltip>
          ) : (
            <ActionButtonStyled $disabled={!isEnabled}>
              {record.status !== 'template'
                ? t('announcements.buttons.params.moveToDrafts')
                : t('announcements.buttons.params.postAd')}
            </ActionButtonStyled>
          )}
        </Popconfirm>

        <Popconfirm
          disabled={!isEnabled}
          onConfirm={onConfirm}
          cancelText={t('payments.popConfirm.No')}
          okText={t('payments.popConfirm.Yes')}
          title={getConfirmTitle}
          {...popConfirmProps}
          overlayInnerStyle={{
            maxWidth:
              record.price < latestPriceRange.min ||
              record.price > latestPriceRange.max
                ? '376px'
                : '260px',
          }}
        >
          {!isEnabled ? (
            <Tooltip
              trigger="hover"
              title={unableEditText}
              placement="top"
              overlayInnerStyle={{ width: 'auto' }}
            >
              <ActionButtonStyled $disabled={!isEnabled}>
                {t('announcements.buttons.params.edit')}
              </ActionButtonStyled>
            </Tooltip>
          ) : (
            <ActionButtonStyled $disabled={!isEnabled}>
              {t('announcements.buttons.params.edit')}
            </ActionButtonStyled>
          )}
        </Popconfirm>
        <Popconfirm
          disabled={!isEnabled}
          onConfirm={() => changeStatus(true)}
          cancelText={t('payments.popConfirm.No')}
          okText={t('payments.popConfirm.Yes')}
          title={t('announcements.buttons.deleteAd1') + '?'}
          {...popConfirmProps}
          overlayInnerStyle={{
            maxWidth:
              record.price < latestPriceRange.min ||
              record.price > latestPriceRange.max
                ? '376px'
                : '260px',
          }}
        >
          {!isEnabled ? (
            <Tooltip
              trigger="hover"
              title={unableEditText}
              placement="top"
              overlayInnerStyle={{ width: 'auto' }}
            >
              <ActionButtonStyled $disabled={!isEnabled}>
                {t('announcements.buttons.params.deleteAd')}
              </ActionButtonStyled>
            </Tooltip>
          ) : (
            <ActionButtonStyled $disabled={!isEnabled}>
              {t('announcements.buttons.params.deleteAd')}
            </ActionButtonStyled>
          )}
        </Popconfirm>
      </FRow>
    </>
  )
}

export default Params

const AdCard = styled.div`
  width: 100%;
  background: #1c1c27;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
`
const CardContainer = styled.div`
  width: 100%;
  display: flex;
  max-height: 600px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`

const FRow = styled.div`
  display: flex;
`

const ActionButtonStyled = styled.div<{ $disabled: boolean }>`
  padding: 8px 16px;
  border-radius: 8px;
  transition: all 0.2s ease;
  cursor: pointer;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: ${({ $disabled }) => ($disabled ? '#474755' : '#FFF')};
  &:hover {
    background: ${({ $disabled }) => ($disabled ? 'transparent' : '#474755')};
  }
`
