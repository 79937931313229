import { Skeleton, Tabs, Typography, useSimpleList } from '@pankod/refine-antd';
import { HttpError, useList, useTranslate } from '@pankod/refine-core';
import {useNavigate, useParams} from '@pankod/refine-react-router-v6';
import {AnnSkeleton, Params} from '../../../features/announcements'
import {DealsList} from 'features/p2p/dealsList/dealsList';
import { useMemo, useState } from 'react';
import {IGetListData} from '../myDeals/cards/myTrades.p';
import {ErrorBoundary} from 'app/ErrorBoundary';
import {useCookiesCustom} from '../../../shared/hooks/useCookieCustom';

export const AnnouncementPage = () => {
    const t = useTranslate();
    const params = useParams();
    const navigate = useNavigate();
    const {removeCookie} = useCookiesCustom();

    const [deals, setDeals] = useState<IGetListData[]>([]);

    const {listProps: data} = useSimpleList({
        dataProviderName: 'p2p',
        resource: `offer`,
        initialFilter: [
            {
                operator: 'and',
                value: [
                    {
                        field: 'id',
                        operator: 'eq',
                        value: `${params.id}`,
                    },
                    {
                        field: 'status',
                        operator: 'ne',
                        value: 'deleted',
                    },
                ],
            },
        ],
    });

      useList<IGetListData, HttpError>({
        dataProviderName: `p2p`,
        resource: `order`,
        config: {
            filters: [{field: 'ad_id', operator: 'eq', value: `${params.id}`}],
        },
        queryOptions: {
            onSuccess: (res) => {
                setDeals([...res.data])
            },
        },
    });

    const calcAdDeals = useMemo(() => {
        const dealsCount = deals.length;
        const successDealsCount = deals.filter((el) => el.status === 'success').length;
        return dealsCount ? `(${successDealsCount}/${dealsCount})` : '';
    }, [deals])

    return (
        <ErrorBoundary
            logout={() => {}}
            navigate={navigate}
            removeCookie={removeCookie}
        >
            <Skeleton
                title={{
                    className: 'skeleton-paragraph-thin skeleton-paragraph',
                }}
                paragraph={{
                    rows: 3,
                    className: 'skeleton-paragraph-medium skeleton-paragraph',
                }}
                loading={!!data.loading}
                active={!!data.loading}
            >
                <div>
                    <Typography.Title>
                        {data?.dataSource &&
                            (data.dataSource[0]?.status !== 'template'
                                ? t(`announcements.adFor${data.dataSource[0].type}`)
                                : t(`announcements.adFor${data.dataSource[0]?.type}Tmpl`))}{' '}
                        {data?.dataSource ? data?.dataSource[0]?.ccy : ''}
                    </Typography.Title>
                    <Tabs
                        items={[
                            {
                                key: 'params',
                                label: t('announcements.adParams'),
                                children: <Params data={data}/>,
                            },
                            {
                                key: 'deals',
                                label: `${t('announcements.adDeals')} ${calcAdDeals}`,
                                children: (
                                  <DealsList
                                    type={'all'}
                                    deals={deals}
                                    isLoading={false}
                                />),
                            },
                        ]}
                    />
                </div>
                {' '}
            </Skeleton>
            <AnnSkeleton loading={!!data.loading}/>
        </ErrorBoundary>
    );
};
