import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

import { TWalletState, useWalletState } from 'useWalletState'
import BN from 'bignumber.js';

const { Text } = Typography;

const BALANCE_CURRENCY = 'USDT'

const UserBalance: FC = () => {
  const balances = useWalletState((state) => state.balances);
  const getBalances = useWalletState((state) => state.getBalances);
  const { t } = useTranslation();
  const [visible, setVisible] = useState(true);

  const getAmountByCurrency = (
    balances: TWalletState['balances'],
    currency: string,
  ) => {
    const foundBalance = balances.find((balance) => balance.name === currency);
    return foundBalance ? foundBalance?.balance?.amount : 0;
  }

  const balance = getAmountByCurrency(balances, BALANCE_CURRENCY);

  const getEye = () => (visible ? <EyeIcon /> : <EyeHiddenIcon />);

  const showBalanceBTC = () => {
    if (!visible) return '************************'

    return `${BN(balance).toFixed(6, BN.ROUND_HALF_DOWN)} ${BALANCE_CURRENCY}`
  }

  const handleClick = () => setVisible((prevState) => !prevState);

  useEffect(() => {
    const updateBalanceInterval = setInterval(() => {
      getBalances();
    }, 5000);

    return () => {
      clearInterval(updateBalanceInterval);
    };
  }, []);

  return (
    <UserBalanceWrapper
    // onClick={() => navigate('/coin-accounts')}
    >
      <BalanceHeader>
        <BalanceHeaderText>{t('header.balance')}</BalanceHeaderText>
        <EyeButton
          onClick={(e) => {
            e.stopPropagation();
            handleClick();
          }}
        >
          {getEye()}
        </EyeButton>
      </BalanceHeader>
      <Balance>{showBalanceBTC()}</Balance>
    </UserBalanceWrapper>
  );
};

export default UserBalance;

const UserBalanceWrapper = styled.div`
  // cursor: pointer;
  height: 32px;

  font-family: 'Inter', sans-serif;

  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: end;
`;

const BalanceHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const BalanceHeaderText = styled(Text)`
  font-size: 10px;
`;

const Balance = styled(Text)`
  font-size: 10px;
  font-weight: bold;
`;

const EyeIcon = styled(EyeOutlined)`
  line-height: 0;
  color: white;
`;

const EyeHiddenIcon = styled(EyeInvisibleOutlined)`
  line-height: 0;
  color: white;
`;

const EyeButton = styled.div`
  font-size: 12px !important;
  cursor: pointer;
  line-height: 0 !important;

  & svg {
    width: 12px;
    height: 12px;
  }
`;
