import { FC } from 'react'
import { Typography } from '@pankod/refine-antd'
import { BaseRecord, HttpError, useList } from '@pankod/refine-core'
import { TFunction } from 'i18next'
import { styled } from 'styled-components'

import { IPaymentType } from 'features/p2p/createOrder/types'
import { IPaymentsType } from 'pages/p2p/dashboard/interface'
import { getPaymentInfoData } from 'shared/helpers/getPaymentInfoData'

interface IProps {
  record: BaseRecord
  type: string
  t: TFunction
  iconsData: ReturnType<typeof useList<IPaymentsType, HttpError>>
}

enum PaymentEnum {
  bankCard = 'bankCardPayment',
  webService = 'webServicePayment',
  bankTransfer = 'bankTransferPayment',
  mobile = 'mobileBalancePayment',
  sbp = 'fastPaymentSystemPayment',
}

const getPaymentType = (
  type: string,
  t: (
    key: string,
    options?: any,
    defaultMessage?: string | undefined,
  ) => string,
) => {
  switch (type) {
    case PaymentEnum.bankCard:
      return t('payments.types.banksCard')
    case PaymentEnum.bankTransfer:
      return t('payments.types.banks')
    case PaymentEnum.sbp:
      return t('payments.types.sbp')
    case PaymentEnum.webService:
      return t('payments.types.webService')
    default:
      return t('payments.types.mobiles')
  }
}

export const PaymentTypesBlock: FC<IProps> = ({
  record,
  type,
  t,
  iconsData,
}) => {
  let arr = record.payment_info ?? []
  if (type !== 'sell') {
    arr = record.buy_payment_info
  }

  const getPaymentDetails = (item: IPaymentType, i: number) => {
    if (type === 'sell') {
      return { ...item, payment_details: item.payment_details }
    } else {
      return { ...item, payment_details: [arr[i]] }
    }
  }

  return arr?.map(
    (item: IPaymentType, i: number) =>
      !item.is_deleted && (
        <div key={i} className={'payment-card'}>
          <FCol style={{ gap: '4px' }}>
            {type === 'sell' && (
              <FRow>
                <GreyThinText>
                  {getPaymentType(item.payment_type, t)}
                </GreyThinText>
              </FRow>
            )}
            <FRow style={{ gap: '8px' }}>
              <FCol className="payment-ico-container">
                <img
                  src={
                    getPaymentInfoData(
                      getPaymentDetails(item, i),
                      t,
                      iconsData.data?.data,
                    )[0].icon
                  }
                  alt={`img-${i}`}
                />
              </FCol>
              <FCol>
                <FCol>
                  <Typography.Text className="text-regular text-white ellipsis-240">
                    {
                      getPaymentInfoData(
                        getPaymentDetails(item, i),
                        t,
                        iconsData.data?.data,
                      )[0].title
                    }
                  </Typography.Text>
                  {getPaymentInfoData(getPaymentDetails(item, i), t)[0]
                    .value && (
                    <LightGreyText className="ellipsis-240">
                      {
                        getPaymentInfoData(
                          getPaymentDetails(item, i),
                          t,
                          iconsData.data?.data,
                        )[0].value
                      }
                    </LightGreyText>
                  )}
                  {type !== 'sell' &&
                    (getPaymentInfoData(getPaymentDetails(item, i), t)[0]
                      .subtext ? (
                      <LightGreyText className="ellipsis-240">
                        {
                          getPaymentInfoData(
                            getPaymentDetails(item, i),
                            t,
                            iconsData.data?.data,
                          )[0].subtext
                        }
                      </LightGreyText>
                    ) : (
                      <LightGreyText className="ellipsis-240">
                        {getPaymentType(item.payment_type, t)}
                      </LightGreyText>
                    ))}
                </FCol>
              </FCol>
            </FRow>
            {type === 'sell' && (
              <FRow>
                {getPaymentInfoData(getPaymentDetails(item, i), t)[0]
                  .subtext ? (
                  <LightGreyText className="ellipsis-240">
                    {
                      getPaymentInfoData(
                        getPaymentDetails(item, i),
                        t,
                        iconsData.data?.data,
                      )[0].subtext
                    }
                  </LightGreyText>
                ) : (
                  <></>
                )}
              </FRow>
            )}
          </FCol>
        </div>
      ),
  )
}

const FRow = styled.div`
  display: flex;
`

const FCol = styled(FRow)`
  flex-direction: column;
`

const GreyThinText = styled(Typography.Text)`
  font-weight: 400;
  color: #767687;
  line-height: 14.52px;
  font-size: 12px;
`

const LightGreyText = styled(GreyThinText)`
  color: #c3c3ce;
`
