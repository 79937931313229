import React from 'react'
import { DatePicker } from '@pankod/refine-antd'
import Icon from '@ant-design/icons'
import dayjs from 'dayjs'
import { RangePickerProps } from 'antd/es/date-picker'
import { LogicalFilter } from '@pankod/refine-core'

import { CaretIcon, DashCircle } from 'shared/components/ui/assets/svg/svgIcons'
import { RangeValue } from '../cards/myTrades.p'

const dateFormat = 'YYYY/MM/DD'

interface IMyOrdersFilters {
  isLoading?: boolean
  startDate: Date | null
  endDate: Date | null
  setFilter: (filter: LogicalFilter | null) => void
  dates?: RangeValue
  setDates: (arg: RangeValue) => void
}

const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  // Can not select days after today
  return current && current > dayjs().endOf('day')
}

const MyDealsDateFilter: React.FC<IMyOrdersFilters> = ({
  setFilter,
  dates,
  setDates,
}) => {
  const pickerStyles = {
    minWidth: '290px',
    height: '40px',
  }

  const onChange = (val: RangeValue) => {
    setDates(val)

    if (val !== null) {
      const hasDateFrom = Boolean(val[0])
      const hasDateTo = Boolean(val[1])

      let filterOperator: LogicalFilter['operator'] = 'gte'
      let filterVal: [unknown, unknown] | unknown = val[0]

      if (hasDateFrom && hasDateTo) {
        filterOperator = 'between'
        filterVal = val
      } else if (!hasDateFrom && hasDateTo) {
        filterOperator = 'lte'
        filterVal = val[1]
      }

      setFilter({
        field: 'created_at',
        operator: filterOperator,
        value: filterVal,
      })
    } else {
      setFilter(null)
    }
  }

  const getIcon = (el: (color?: string | undefined) => JSX.Element) => (
    <Icon component={() => el()} />
  )

  return (
    <>
      <DatePicker.RangePicker
        style={pickerStyles}
        className={'cursor-pointer'}
        format={dateFormat}
        suffixIcon={getIcon(CaretIcon)}
        clearIcon={getIcon(DashCircle)}
        separator={<>-</>}
        disabledDate={disabledDate}
        value={dates || undefined}
        onCalendarChange={(val) => {
          onChange(val)
        }}
        onChange={(val) => {
          onChange(val)
        }}
      />
    </>
  )
}

export default MyDealsDateFilter
